@import '@hiredigital/ui/variables';
@import '@hiredigital/ui/fonts';
@import 'src/styles/normalize';
// @import 'react-datepicker/dist/react-datepicker-cssmodules.css';

html {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: $cerebri;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 40px;
  font-weight: 400;
  margin: 0px;
}

h2 {
  font-size: 28px;
  font-weight: $fw-2;
  margin: 0px;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

button,
input,
optgroup,
select,
textarea {
  outline: none;
  font-family: $cerebri;
}

p {
  font-size: 16px;
  line-height: 26px;
  font-weight: $fw-1;
  margin: 0px;

  @include for-size(phone-only) {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

strong,
b {
  font-weight: bold;
}

q {
  quotes: '\201C''\201D''\2018''\2019';
  padding: 10px 20px;
  font-size: 18px;
}

q:before {
  content: open-quote;
  display: inline;
  height: 0;
  line-height: 0;
  position: relative;
  top: 5px;
  color: $black;
  font-size: 1.5em;
}

q:after {
  content: close-quote;
  display: inline;
  height: 0;
  line-height: 0;
  position: relative;
  top: 5px;
  color: $black;
  font-size: 1.5em;
}

// Global CSS - Do not use unless 100% necessary. For use with external

// #intercom-container .intercom-app.intercom-app--footer {
//   .intercom-launcher-frame {
//     display: none;
//   }

//   .intercom-launcher {
//     display: none;
//   }

//   .intercom-messenger-frame-enter-done ~ .intercom-launcher-frame {
//     display: block;
//   }

//   .intercom-messenger-frame-enter-done ~ .intercom-launcher {
//     display: block;
//   }
// }

// .g-object-fit-fallback {
//   min-height: 100%;
//   max-height: 100%;
//   background-size: cover;
// }

// .gtm {
//   height: 1px;
//   width: 1px;
//   background-color: transparent;
//   color: transparent;
// }

// .gtm--hidden {
//   visibility: hidden;
// }

// .gtm--visible {
//   visibility: visible;
// }
